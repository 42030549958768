
































































import Vue from "vue";
import BootstrappedProject from "@/components/BootstrappedProject.vue";
import PreFooter from "@/components/PreFooter.vue";
import projects from "@/assets/data/bootstrapped.json";

export default Vue.extend({
  components: {
    BootstrappedProject,
    PreFooter,
  },
  data() {
    return {
      projects,
    };
  },
  metaInfo(): { title: string } {
    return this.$route.meta;
  },
});
